export const state = () => ({
  balance: 0,
  has_rebate: null,
  username: '',
  USDT: 0,
  rates: {},
  settings: {},
  upgradeDialog: false,
})

export const mutations = {
  change_balance(state, value) {
    state.balance = value
  },
  change_has_rebate(state, value) {
    state.has_rebate = value
  },
  change_username(state, value) {
    state.username = value
  },
  change_usdt(state, value) {
    state.USDT = value
  },
  change_rates(state, value) {
    state.rates = value
  },
  change_settings(state, value) {
    state.settings = value
  },
  setDialog(state, value) {
    state.upgradeDialog = value;
  },
}