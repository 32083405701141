//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapMutations, mapState} from "vuex";

export default {
  data() {
    return {
      allAccounts: false,
      loading: false,
    }
  },
  computed: {
    ...mapState(['settings', 'upgradeDialog']),
  },
  methods: {
    async updateVersion() {
      this.loading = true
      await this.$axios.$post('/bot/upgrade_version/', {allAccounts: this.allAccounts})
        .then(() => {
          window.location.reload()
        }).catch(() => {
          this.loading = false
        })
    },
    ...mapMutations(['setDialog', 'upgradeVersion']),
  }
}
