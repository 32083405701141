//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex'
import ActivateApiKey from "@/components/mixins/ActivateApiKey";

export default {
  name: 'NavBar',
  mixins: [ActivateApiKey, ],
  data() {
    return {
      drawer: false,
      dtrm: 'loading...',
      api_key_labels: []
    }
  },
  methods: {
    redirect() {
      if (window.location.host === 'tradeium.io' && this.user.is_US) {
        this.$auth.logout()
        window.location = window.location.href.replace('tradeium.io', 'us.tradeium.io')
      }
      if (window.location.host === 'dev.tradeium.io' && this.user.is_US) {
        this.$auth.logout()
        window.location = window.location.href.replace('dev.tradeium.io', 'us.dev.tradeium.io')
      }
    },
    goTo(url, target = '_self') {
      window.open(url, target)
    },
    get_dtrm() {
      this.$axios.get(`/metamask/dtrm/`).then(res => {
        this.dtrm = res.data.dtrm.toFixed(1)
      }).catch(error => {
        this.dtrm = 'error'
      })
    },
    get_labels() {
      this.$axios.$get('/profiles/list/labels/').then(response => {
        this.api_key_labels = response.filter(label => label !== this.user.selected_api_key.label)
      })
    },
    async process_api_change(label) {
      this.api_key_labels = this.api_key_labels.filter(api_key_label => api_key_label !== label)
      this.api_key_labels.push(this.user.selected_api_key.label)
      await this.activate_api_key(label)
      this.drawer = false
      this.$emit('forceRerender')
    }
  },
  mounted() {
    if (this.has_wallet) {
      this.get_dtrm()
    }
    if (this.user) {
      this.redirect()
      this.get_labels()
    }
  },
  computed: {
    credits() {
      return parseFloat(this.user.credit).toFixed(1)
    },
    days_left() {
      if (this.user.days_left === null) {
        return
      }
      if (this.user.days_left > 0) {
        return `${this.user.days_left} days left`
      } else if (this.user.days_left === 0) {
        return 'Last day'
      } else if (this.user.days_left <= 0) {
        return 'No days left'
      }
    },
    has_wallet() {
      return this.loggedIn && this.user && this.user.wallet && this.user.wallet.public_address && this.user.wallet.redeemed
    },
    ...mapState('auth', ['loggedIn', 'user', ]),
  },
  watch: {
    user(value){
      if (value.is_US) {
        this.redirect()
      }
    },
    '$auth.loggedIn': {
      handler(value) {
        if (value) {
          this.get_labels()
        }
      }
    },
    'user.wallet.redeemed': {
      handler(new_value) {
        if (new_value) {
          this.get_dtrm()
        }
      }
    }
  }
}
