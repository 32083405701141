export default {
  name: 'ForceRefresh',
  data() {
    return  {
      componentKey: 0,
    }
  },
  methods: {
    forceRerender() {
      // https://michaelnthiessen.com/force-re-render/
      this.componentKey += 1;
      console.log(`rerender ${this.componentKey}`)
    }
  }
}