//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavBar from "../components/NavBar";
import ForceRefresh from '../components/mixins/ForceRefresh'
import {mapState, mapMutations} from 'vuex'
import UpgradeDialog from "@/components/UpgradeDialog.vue";


export default {
  name: 'App',
  mixins: [ForceRefresh],
  components: {
    NavBar,
    UpgradeDialog
  },
  data() {
    let is_dark_mode = localStorage.getItem('dark_theme', false) === 'true'
    this.$vuetify.theme.dark = is_dark_mode
    return  {
      dark_theme: this.$vuetify.theme.dark,
      dismissed: localStorage.getItem('dismissed'),
    }
  },
  computed: {
    error() {
      if (this.loggedIn && this.user.selected_api_key && !this.user.selected_api_key.approved) {
        return 'Your API keys are invalid. Bot was disabled.'
      }
    },
    ...mapState('auth', ['loggedIn', 'user', 'settings']),
    ...mapState(['settings', 'upgradeDialog']),
  },
  methods: {
    set_dark_theme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.dark_theme = this.$vuetify.theme.dark
      localStorage.setItem('dark_theme', this.$vuetify.theme.dark)
    },
    storeDismissed() {
      localStorage.setItem('dismissed', '1')
    },
    goTo(url, target = '_self') {
      window.open(url, target)
    },
    ...mapMutations(['setDialog']),
  },
  watch: {
    loggedIn() {
      if (this.loggedIn) {
        this.$axios.$get(
          `/bot/settings/`
        ).then(resp => {
          this.$store.commit('change_settings', resp)
        })
      }
    }
  }
}

