export default function ({$axios, app}, inject) {
  $axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const code = parseInt(error.response && error.response.status);

      if (error.message === 'Network Error') {
        error.message = 'We\'re performing a maintenance. Check again later.'
      }

      if ([401,].includes(code)) {
        app.$auth.logout();
      } else if ([400,].includes(code)) {
        if (error.response.data.non_field_errors) {
          app.$toast.error(...error.response.data.non_field_errors)
        }
      } else if ([406,].includes(code)) {
        app.$toast.global.defaultError({
          msg: error.response.data.message
        })
      } else if ([503,].includes(code)) {
        app.$toast.global.defaultError({
          msg: 'We\'re performing a maintenance.'
        })
      } else if ([500,].includes(code)) {
        app.$toast.global.defaultError({
          msg: 'There was a server error. Please try again later.'
        })
      }

      return Promise.reject(error);
    }
  );
}