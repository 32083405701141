

export default {
  methods: {
    async activate_api_key(label) {
      this.loading = true
      try {
        let resp = await this.$axios.$patch('/users/activate_api_key/', {label})
        const updatedUser = {...this.$auth.user}
        updatedUser.selected_api_key = resp.selected_api_key
        this.$auth.setUser(updatedUser)
        this.$toast.success(`Switched to ${label} API key!`)
      } catch (err) {
         this.$toast.error(err.response.data)
      }
      this.loading = false
    },
  },
}
